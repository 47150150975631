export default function locale(en, zh_t, zh_s) {
    if (localStorage.getItem("language") === "ENG") {
        return en;
    }
    else if (localStorage.getItem("language") === "ZH-S")
    {
        return zh_s;
    }
    else if (localStorage.getItem("language") === "ZH-T" || localStorage.getItem("language") === null)
    {
        return zh_t;
    }
}