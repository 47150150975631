import React from 'react';
import ReactDOM from 'react-dom';
import { Navbar } from './jsx/Navbar';
import { App } from './jsx/App';
import { QuickReload } from './jsx/QuickReload';
import { Terms } from './jsx/Terms';
import { Priv } from './jsx/Priv';
import Memorize from "./jsx/Memorize";
import AddSubAnim from "./jsx/AddSubAnim";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import locale from './jsx/normaljs/locale';

//import wechat from './img/wechat.jpg';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navbar />

      <Switch>
        <Route path="/app" component={App} />
        <Route path="/quickRef" component={QuickReload} />
        <Route path="/tos" component={Terms} />
        <Route path="/priv" component={Priv} />
        <Route path="/memorize" component={Memorize} />
        <Route path="/anim" component={AddSubAnim} />
        <Route path="/">
          <Redirect to="/app" />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
var html = document.querySelector("html");
if (locale(false, true, false)) {
  html.setAttribute("lang", "zh-Hans");
} else if (locale(false, false, true)) {
  html.setAttribute("lang", "zh-Hant")
}