import React from 'react';
import '../css/core.css';
import '../css/quickprac.css';
import { useHistory, Link } from 'react-router-dom';
import locale from './normaljs/locale';
import wechat from "../img/wechat.jpg";

export class QuickPrac extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //main section visibility
            modeD: "block",
            configD: "none",
            multDISP: "none",
            divDISP: "none",
            buttonDISP: "block",

            //configuration data
            //input values
            numOfDigit: "",
            totalNumbers: "",
            timePer: "",

            mult: {
                c1: "",
                c2: "",
                c2_1: "",
                c2_2: "",
                c0_1: "",
                c0_2: "",
            },

            div: {
                maxAns: "",
                minAns: "",
                minDiv: "",
                maxDiv: "",
                noQs: "",
                taskDuration: "",
                doRemainder: false,
            },

            //redir hook
            chngURL: "",

            addD: "none", //section to configure
            multD: "none",

            ooh_ooh_aah_aah: "block",
        }
        //normal variables
        this.qGen = {
            launch: true
        }

        this.modeLocked = false

        //function bindings
        this.modeClick = this.modeClick.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.configInputChanged = this.configInputChanged.bind(this);
        this.start = this.start.bind(this);
        this.oc1 = this.oc1.bind(this);
        this.oc2 = this.oc2.bind(this);
        this.oc2_1 = this.oc2_1.bind(this);
        this.oc2_2 = this.oc2_2.bind(this);
        this.handleMultOC = this.handleMultOC.bind(this);
        this.mult = this.mult.bind(this);
        this.div = this.div.bind(this);
        this.oc0_1 = this.oc0_1.bind(this);
        this.oc0_2 = this.oc0_2.bind(this);
        this.handleDivOC = this.handleDivOC.bind(this);
        this.ooh_ooh_clik = this.ooh_ooh_clik.bind(this);
    }

    render() {

        return (
            <div className="text-center noselect">
                <br />
                <h1>{locale("ABC Abacus", "志成珠心算", "志成珠心算")}</h1><br />

                <ChooseMode modeD={this.state.modeD} modeClick={this.modeClick} div={this.div} mult={this.mult} />
                <div className={`d-${this.state.configD}`}>
                    <AdditionMode configInputChanged={this.configInputChanged} numOfDigit={this.state.numOfDigit} totalNumbers={this.state.totalNumbers} timePer={this.state.timePer} btnToggle={this.btnToggle} start={this.start} addD={this.state.addD} addBS={this.state.addBS} subBS={this.state.subBS} subStart={this.state.subStart} />
                    <MultDiv multD={this.state.multD} buttonDISP={this.state.buttonDISP} mult={this.mult} div={this.div} multDISP={this.state.multDISP} oc1={this.oc1} oc2={this.oc2} oc2_1={this.oc2_1} oc2_2={this.oc2_2} c1={this.state.mult.c1} c2={this.state.mult.c2} c2_1={this.state.mult.c2_1} c2_2={this.state.mult.c2_2} c0_1={this.state.mult.c0_1} oc0_1={this.oc0_1} c0_2={this.state.mult.c0_2} oc0_2={this.oc0_2} start={this.start} divDISP={this.state.divDISP} divStates={this.state.div} divMethod={this.handleDivOC} />
                </div>

                <br /><br /><br />
                <h2> {locale("Wechat", "微信", "微信")}: </h2>
                <div className="text-center noselect" >
                    <img src={wechat} style={{ width: "125px", height: "125px", textAlign: "center" }} alt="wechat" />
                </div>


                {this.state.chngURL}
                {/*<div className={`text-center adsf d-${this.state.ooh_ooh_aah_aah}`}>
                    We use cookies to enhance your experience on this site. By continuing to browse this site, you agree to the <Link to="/priv" style={{ color: "white", textDecoration: "underline" }} >Privacy Policy</Link>
                    &nbsp;and <Link to="/tos" style={{ color: "white", textDecoration: "underline" }} >Terms of Service</Link>.
                    &nbsp;&nbsp;
                    <button className="btn btn-success" onClick={this.ooh_ooh_clik} >I Agree</button>
        </div>*/}
            </div>
        )
    }

    modeClick(item) {
        if (!this.modeLocked) {
            this.modeLocked = true;

            this.changeMode("configD", () => {
                if (item === "addSub") {
                    this.setState({ addD: "block" });
                    this.qGen.mode = item
                }
                else if (item === "multDiv") {
                    this.setState({ multD: "block" });
                    this.qGen.mode = item
                }
            })
        }
    }

    changeMode(mode, callback) {
        this.setState({
            configD: "none",
            modeD: "none"
        }, () => {
            this.setState({
                [mode]: "block"
            }, callback);
        });
    }

    configInputChanged(id, evt) {
        if (!isNaN(Number(evt.target.value))) {
            this.setState({
                [id]: evt.target.value
            })
        }
    }

    start() {
        //console.log(this.qGen.mode)
        var url = ""
        let doit = true;
        if (this.qGen.mode === "addSub") {
            url = "/app/as?run="
            this.qGen.numDigit = Number(this.state.numOfDigit);
            this.qGen.totalNumbers = Number(this.state.totalNumbers);
            this.qGen.timePer = Number(this.state.timePer);
            let qGens = [this.qGen.numDigit, this.qGen.totalNumbers, this.qGen.timePer]

            if (qGens.indexOf(0) !== -1) {
                this.qGen.launch = false;
                window.alert("Please make sure that all fields are filled in correctly, then continue.");
            }
            else this.qGen.launch = true;
            url += btoa(JSON.stringify(this.qGen));
        }
        else if (this.qGen.mode === "mult") {
            url = "/app/md?run=";
            this.qGen.min1 = Number(this.state.mult.c1);
            this.qGen.max1 = Number(this.state.mult.c2);
            this.qGen.min2 = Number(this.state.mult.c2_1);
            this.qGen.max2 = Number(this.state.mult.c2_2);
            this.qGen.noSum = Number(this.state.mult.c0_1);
            this.qGen.duration = Number(this.state.mult.c0_2);

            if ((this.qGen.min1 || this.qGen.min2 || this.qGen.max1 || this.qGen.max2 || this.qGen.noSum || this.qGen.duration) === 0
                || isNaNs(this.qGen.min1, this.qGen.min2, this.qGen.max1, this.qGen.max2, this.qGen.noSum, this.qGen.duration)) {
                this.qGen.launch = false;
                window.alert("Please make sure that all fields are filled in correctly, then continue.");
            }
            else this.qGen.launch = true;
            url += btoa(JSON.stringify(this.qGen));
        }
        else if (this.qGen.mode === "div") {
            url = "/app/md?run="
            this.qGen.maxAns = Number(this.state.div.maxAns);
            this.qGen.minAns = Number(this.state.div.minAns);
            this.qGen.minDiv = Number(this.state.div.minDiv);
            this.qGen.maxDiv = Number(this.state.div.maxDiv);
            this.qGen.noSum = Number(this.state.div.noQs);
            this.qGen.duration = Number(this.state.div.taskDuration);
            this.qGen.doRemainder = this.state.div.doRemainder;

            if ((this.qGen.minAns || this.qGen.maxAns || this.qGen.minDiv || this.qGen.maxDiv || this.qGen.noSum || this.qGen.duration) === 0
                || isNaNs(this.qGen.minAns, this.qGen.maxAns, this.qGen.minDiv, this.qGen.maxDiv, this.qGen.noSum, this.qGen.duration)) {
                this.qGen.launch = false;
                window.alert("Please make sure that all fields are filled in correctly, then continue.");
            }
            else this.qGen.launch = true;
            url += btoa(JSON.stringify(this.qGen));
        }
        else return;
        if (this.qGen.launch && doit) {
            delete this.qGen.launch;
            this.setState({ chngURL: <ChangeUrl url={url} /> });
        }
    }

    oc1(evt) { this.handleMultOC(evt, 0) }
    oc2(evt) { this.handleMultOC(evt, 1) }
    oc2_1(evt) { this.handleMultOC(evt, 2.1) }
    oc2_2(evt) { this.handleMultOC(evt, 2.2) }
    oc0_1(evt) { this.handleMultOC(evt, 10.1) }
    oc0_2(evt) { this.handleMultOC(evt, 20.2) }

    mult() {
        this.setState({ buttonDISP: "none", multDISP: "block", multD: "block", configD: "block", modeD: "none" });
        this.qGen.mode = "mult";
    }
    div() {
        this.setState({ buttonDISP: "none", divDISP: "block", multD: "block", configD: "block", modeD: "none" });
        this.qGen.mode = "div";
    }

    handleMultOC(evt, id) {
        if (!isNaN(Number(evt.target.value))) {
            if (id === 0) {
                let a = this.state.mult;
                a.c1 = evt.target.value;
                this.setState({ mult: a });
            }
            else if (id === 1) {
                let a = this.state.mult;
                a.c2 = evt.target.value;
                this.setState({ mult: a });
            }
            else if (id === 2.1) {
                let a = this.state.mult;
                a.c2_1 = evt.target.value;
                this.setState({ mult: a });
            }
            else if (id === 2.2) {
                let a = this.state.mult;
                a.c2_2 = evt.target.value;
                this.setState({ mult: a });
            }
            else if (id === 10.1) {
                let a = this.state.mult;
                a.c0_1 = evt.target.value;
                this.setState({ mult: a });
            }
            else if (id === 20.2) {
                let a = this.state.mult;
                a.c0_2 = evt.target.value;
                this.setState({ mult: a });
            }
        }
    }

    handleDivOC(evt, id) {
        //console.log('update');
        if (!isNaN(Number(evt.target.value)) || id === "doRemainder") {
            let a = this.state.div;
            a[id] = evt.target.value;
            this.setState({ div: a })
        }
    }

    ooh_ooh_clik() {
        this.setState({ ooh_ooh_aah_aah: "none" });
    }
}

function ChooseMode(props) {
    const history = useHistory();
    function memorize() {
        history.push("/memorize");
    }
    function anim() {
        history.push("/anim");
    }

    return (
        <div className={`d-${props.modeD}`}>
            <h2>{locale("Please Choose Mode", "請選擇模式", "请选择模式")}:</h2><br />
            <button className="btn btn-primary chooseBtn" onClick={() => { props.modeClick("addSub") }} >{locale("Addition and Subtraction", "加減法", "加减法")}</button>
            <span id="chooseDSpace">&nbsp;&nbsp;</span>
            <br className="chooseBR" /><br className="chooseBR" />
            <button className="btn btn-primary chooseBtn" onClick={props.mult} >{locale("Multiplication", "乘法", "乘法")}</button>
            <span id="chooseDSpace">&nbsp;&nbsp;</span>
            <br className="chooseBR" /><br className="chooseBR" />
            <button className="btn btn-primary chooseBtn" onClick={props.div} >{locale("Division", "除法", "除法")}</button>
            <br className="chooseBR" /><br className="chooseBR" />
            <span id="chooseDSpace">&nbsp;&nbsp;</span>
            <button className="btn btn-primary chooseBtn" onClick={() => memorize()} >{locale("Memorization", "記憶力訓練", "记忆力训练")}</button>
            <br className="chooseBR" /><br className="chooseBR" />
            <span id="chooseDSpace">&nbsp;&nbsp;</span>
            <button className="btn btn-primary chooseBtn" onClick={() => anim()} >{locale("Animated Addition & Subtraction", "滾動數字訓練", "滚动数字训练")}</button>
        </div>
    )
}

function AdditionMode(props) {
    return (
        <div className={`d-${props.addD}`} >
            <h2>{locale("Addition and Subtraction", "加法和減法", "加法和减法")}</h2>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <input type="number" className="form-control w-100 text-center adaafasfasdfasdf" placeholder={locale("Number of Digits", "運算位數", "运算位数")} onChange={evt => props.configInputChanged("numOfDigit", evt)} value={props.numOfDigit} />
                    </div>
                </div><br />
                <div className="row justify-content-center">
                    <div className="col">
                        <input type="number" className="form-control w-100 text-center adaafasfasdfasdf" placeholder={locale("Total Numbers to Add", "運算數量", "运算数量")} onChange={evt => props.configInputChanged("totalNumbers", evt)} value={props.totalNumbers} />
                    </div>
                </div><br />
                <div className="row justify-content-center">
                    <div className="col">
                        <input type="number" className="form-control w-100 text-center adaafasfasdfasdf" placeholder={locale("Time per calculation", "運算間隔時間", "运算间隔时间")} onChange={evt => props.configInputChanged("timePer", evt)} value={props.timePer} />
                    </div>
                </div><br />
                <div className="row">
                    <div className="col">
                        <button className="btn btn-primary" onClick={() => props.start()} >{locale("Start", "開始", "开始")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MultDiv(props) {
    return (
        <div className={`d-${props.multD}`}>
            <div className="container">
                <div className="row">
                    <div className={`col d-${props.multDISP}`}>
                        <h2>{locale("Multiplication", "乘法", "乘法")}</h2>
                        <MultDesktop oc1={props.oc1} oc2={props.oc2} oc2_1={props.oc2_1} oc2_2={props.oc2_2}
                            val1={props.mult.c1} val2={props.mult.c2} val2_1={props.mult.c2_1} val2_2={props.mult.c2_2}
                            c0_1={props.mult.c0_1} oc0_1={props.oc0_1} c0_2={props.mult.c0_2} oc0_2={props.oc0_2} />
                        <MultMobile oc1={props.oc1} oc2={props.oc2} oc2_1={props.oc2_1} oc2_2={props.oc2_2}
                            val1={props.mult.c1} val2={props.mult.c2} val2_1={props.mult.c2_1} val2_2={props.mult.c2_2}
                            c0_1={props.mult.c0_1} oc0_1={props.oc0_1} c0_2={props.mult.c0_2} oc0_2={props.oc0_2} />

                        <button className="btn btn-primary" onClick={props.start} >{locale("Start", "開始", "开始")}</button>
                    </div>
                </div>
                <div className="row">
                    <div className={`col d-${props.divDISP}`}>
                        <h2>{locale("Divison", "除法", "除法")}</h2>
                        <DivDesktop states={props.divStates} method={props.divMethod} />
                        <DivMobile states={props.divStates} method={props.divMethod} /><br />
                        <button className="btn btn-primary" onClick={props.start}>{locale("Start", "開始", "开始")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ChangeUrl(props) {
    var history = useHistory();

    history.push(props.url);
    return null;
}

function MultDesktop(props) {
    return (
        <div id="qpracmdesktop" className="custom-control custom-checkbox" >
            <input type="number" className="form-control text-center w-50 d-inline" placeholder={locale("Min number to multiply by", "最小被乘數", "最小被乘数")} value={props.val2_1} onChange={props.oc2_1} />
            <input type="number" className="form-control text-center w-50 d-inline" placeholder={locale("Max number to multiply by", "最大被乘數", "最大被乘数")} value={props.val2_1} onChange={props.oc2_2} />
            <input type="number" className="form-control text-center w-50 d-inline" placeholder={locale("Min Number", "最小乘數", "最小乘数")} value={props.val1} onChange={props.oc1} />
            <input type="number" className="form-control text-center w-50 d-inline" placeholder={locale("Max Number", "最大乘數", "最大乘数")} value={props.val2} onChange={props.oc2} />
            <input type="number" className="form-control text-center" placeholder={locale("Number of Questions", "運算數量", "运算数量")} value={props.c0_1} onChange={props.oc0_1} />
            <input type="number" className="form-control text-center" placeholder={locale("Task Duration", "運算時間設置", "运算时间设置")} value={props.c0_2} onChange={props.oc0_2} /><br />
        </div>
    )
}

function MultMobile(props) {
    return (
        <div id="qpracmmobile" >
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Min number to multiply by", "最小被乘數", "最小被乘数")} value={props.val2_1} onChange={props.oc2_1} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Max number to multiply by", "最大被乘數", "最大被乘数")} value={props.val2_1} onChange={props.oc2_2} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Min Number", "最小乘數", "最小乘数")} value={props.val1} onChange={props.oc1} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Max Number", "最大乘數", "最大乘数")} value={props.val2} onChange={props.oc2} />
            <input type="number" className="form-control text-center" placeholder={locale("Number of Questions", "運算數量", "运算数量")} value={props.c0_1} onChange={props.oc0_1} />
            <input type="number" className="form-control text-center" placeholder={locale("Task Duration", "運算時間設置", "运算时间设置")} value={props.c0_2} onChange={props.oc0_2} /><br />
        </div>
    )
}

function DivDesktop(aasdfenqerpqiernpqeirpqeirnqpweriqpern) {
    var props = aasdfenqerpqiernpqeirpqeirnqpweriqpern.states;
    var method = aasdfenqerpqiernpqeirpqeirnqpweriqpern.method;
    return (
        <div id="qpracddesktop" >
            <input type="number" className="form-control text-center d-inline w-50" placeholder={locale("Max Answer", "最大結果", "最大结果")} value={props.maxAns} onChange={(evt) => { method(evt, "maxAns") }} />
            <input type="number" className="form-control text-center d-inline w-50" placeholder={locale("Min Answer", "最小結果", "最小结果")} value={props.minAns} onChange={(evt) => { method(evt, "minAns") }} />
            <input type="number" className="form-control text-center d-inline w-50" placeholder={locale("Max Num to Divide by", "最大除數", "最大除数")} value={props.maxDiv} onChange={(evt) => { method(evt, "maxDiv") }} />
            <input type="number" className="form-control text-center d-inline w-50" placeholder={locale("Min Number to Divide by", "最小除數", "最小除数")} value={props.minDiv} onChange={(evt) => { method(evt, "minDiv") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Number of Questions", "運算數量", "运算数量")} value={props.noQs} onChange={(evt) => { method(evt, "noQs") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Task Duration", "運算時間設置", "运算时间设置")} value={props.taskDuration} onChange={(evt) => { method(evt, "taskDuration") }} />
            <div className="custom-control custom-checkbox" >
                <input type="checkbox" className="custom-control-input" id="useRemainder" checked={props.doRemainder} onChange={(evt) => method({ target: { value: evt.target.checked } }, "doRemainder")} />
                <label className="custom-control-label" for="useRemainder" >{locale("Use Remainders", "有餘數", "有余数")}</label>
            </div>
        </div>
    )
}

function DivMobile(asdfpqnfadsfhqufoiauhbfoiuebroiwbf) {
    var props = asdfpqnfadsfhqufoiauhbfoiuebroiwbf.states;
    var method = asdfpqnfadsfhqufoiauhbfoiuebroiwbf.method;
    return (
        <div id="qpracdmobile">
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Max Answer", "最大結果", "最大结果")} value={props.maxAns} onChange={(evt) => { method(evt, "maxAns") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Min Answer", "最小結果", "最小结果")} value={props.minAns} onChange={(evt) => { method(evt, "minAns") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Max Num to Divide by", "最大除數", "最大除数")} value={props.maxDiv} onChange={(evt) => { method(evt, "maxDiv") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Min Number to Divide by", "最小除數", "最小除数")} value={props.minDiv} onChange={(evt) => { method(evt, "minDiv") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Number of Questions", "運算數量", "运算数量")} value={props.noQs} onChange={(evt) => { method(evt, "noQs") }} />
            <input type="number" className="form-control text-center d-inline" placeholder={locale("Task Duration", "運算時間設置", "运算时间设置")} value={props.taskDuration} onChange={(evt) => { method(evt, "taskDuration") }} />
            <div className="custom-control custom-checkbox" >
                <input type="checkbox" className="custom-control-input" id="useRemainder" checked={props.doRemainder} onChange={(evt) => method({ target: { value: evt.target.checked } }, "doRemainder")} />
                <label className="custom-control-label" for="useRemainder" >{locale("Use Remainders", "有餘數", "有余数")}</label>
            </div>
        </div>
    )
}

function isNaNs(...arr) {
    var nan = false;
    arr.forEach(function (item) {
        if (nan === false) {
            nan = isNaN(item);
        }
    })
    return nan;
}