import React from 'react';

export class Priv extends React.Component {
    render() {
        return (
            <div>
                Privacy Policy:

                We may use cookies a third party partner, Google to analyze user activity to improve the Service.
                For example, we can use Cookies to monitor the amount of users and the location of the users so that we may optimize the location of the servers
                to provide a better experience for you.

                We may also use cookies to store localized data such as your language preferences. 
            </div>
        )
    }
}