import React, { useEffect, useRef, useState } from "react";
import locale from "./normaljs/locale";

export default function AddSubAnim() {
    const [view, setView] = useState(0);
    const [config, setConfig] = useState({});

    return (<>
        {view === 0 ? <Config start={(numToAdd, noDigits) => { setView(1); setConfig({ numToAdd: numToAdd, noDigits: noDigits }) }} /> : <Task {...config} setView={(a) => setView(a)} />}
    </>
    )
}

function Config({ start }) {
    const [numToAdd, setNumToAdd] = useState();
    const [noDigits, setDigits] = useState();

    return (
        <>
            <div className="d-flex flex-column align-items-center" >
                <div className="mt-3 w-25" >
                    <input type="number" className="form-control adaafasfasdfasdf text-center" placeholder={locale("Numbers to Add", "多少組數字?", "多少组数字?")} value={numToAdd} onChange={(evt) => setNumToAdd(evt.target.value)} />
                </div>
                <div className="mt-3 w-25" >
                    <input type="number" className="form-control adaafasfasdfasdf text-center" placeholder={locale("No. of Digits", "幾位数", "几位數")} value={noDigits} onChange={(evt) => setDigits(evt.target.value)} />
                </div>
                <div className="mt-3" >
                    <button className="btn btn-primary" onClick={() => start(Number(numToAdd), Number(noDigits))} >{locale("Start", "開始", "开始")}</button>
                </div>
            </div>
        </>
    )
}

function Task({ numToAdd, noDigits, setView }) {
    const [numArray, setNumArray] = useState([]);
    const [answer, setAnswer] = useState(0);
    const [restart, setRestart] = useState(false);
    const [answerShown, setAnswerShown] = useState(false);

    useEffect(() => {
        var arr = [];
        var total = 0;
        for (var i = 0; i < numToAdd; i++) {
            var val = random(noDigits)

            while (val === arr[arr.length - 1]) {
                val = random(noDigits);
            }

            if (total + val <= 0) val = val * -1;
            arr.push(val);
            total += val;
        }
        setNumArray(arr);
        setAnswer(total);
    }, [noDigits, numToAdd, restart])

    return (
        <div className="d-flex flex-column" >
            <div className="d-flex flex-column align-items-center scrollbar-hide flex-wrap vh-75" >
                {numArray.map((val, index) => (<div key={index} ><h1>{val}</h1></div>))}
                <div>

                </div>
                <div>
                    <button className={"btn btn-primary mb-2 position-fixed"} style={{ bottom: 10, left: 10 }} onClick={() => window.location.pathname = "/"} >{locale("Go Home", "首頁", "首页")}</button>
                </div>
                <div>
                    <button className="btn btn-primary mb-2 position-fixed" style={{ bottom: 50, left: 10 }} onClick={() => { setAnswerShown(false); setRestart(!restart); }} >{locale("Restart", "下一题", "下一題")}</button>
                </div>
            </div>
            <div className={`d-flex justify-content-center text-center`} >
                {!answerShown ? <button className={`btn btn-primary mb-2 ${answerShown ? "d-none" : "d-block"}`} onClick={() => { setAnswerShown(true) }} >{locale("Show Answer", "答案", "答案")}</button>
                    : <h1>{locale("Answer:", "答案", "答案")} {answer}</h1>}
            </div>
        </div>
    )
}

function random(digit) {
    const max = Math.pow(10, digit) - 1;
    const min = Math.pow(10, digit - 1);
    return (Math.round(Math.random() * (parseFloat(max) - parseFloat(min))) + parseFloat(min)) * (randomBoolean(0.8) ? 1 : -1);
}

// chance of value being true, e.g. 70% true is 0.7
function randomBoolean(chance) {
    return Math.random() < chance;
}