import React from 'react'
import icon from '../img/icon.png';
import language from './normaljs/locale'
import { Link } from 'react-router-dom';
import '../css/navbar.css';


export class Navbar extends React.Component {

    render() {

        var locale = (
            <ul className="navbar-nav mr-auto">
                <li className={`navbar-item`}>
                    <Link to="/" className="navbar-brand">
                        <img src={icon} width="30" height="30" alt="logo" className="mr-3" />
                        {language("Home", "主页", "主页")}
                    </Link>
                </li>
            </ul>
        )

        //return navbar
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light noselect" id="desktopNav" >

                {locale}

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <button className="btn btn-light dropdown-toggle" id="languageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            Language/语言
                        </button>
                        <div className="dropdown-menu" aria-labelledby="languageDropdown">
                            <button className="dropdown-item" onClick={() => { localStorage.setItem("language", "ENG"); window.location.reload() }} >English</button>
                            <button className="dropdown-item" onClick={() => { localStorage.setItem("language", "ZH-S"); window.location.reload() }} >中文(简体)</button>
                            <button className="dropdown-item" onClick={() => { localStorage.setItem("language", "ZH-T"); window.location.reload() }} >中文(繁體)</button>
                        </div>
                    </li>

                </ul>
            </nav>
        )
    }


}