import React from 'react';
import '../css/taskAS.css';
import '../css/core.css';
import { useHistory } from 'react-router-dom';
import locale from './normaljs/locale';

export class TaskAS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalQuestions: 0,
            currentQuestion: 0,
            secLeft: 0,
            questionNum: "Loading ...",
            qDISP: "block",
            dDISP: "none",
            correct: 0,
            accuracy: 0,
            checkAnsInput: "",
            dDISP1: "flex",
            dDISP2: "none",
            correctAnswer: 0,
            isCorrect: "",
        }

        this.initiateTask = this.initiateTask.bind(this);
        this.startCountDown = this.startCountDown.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this)
        this.taskEnd = this.taskEnd.bind(this);
        this.checkAns = this.checkAns.bind(this);
        this.checkAnsInputOC = this.checkAnsInputOC.bind(this);
        this.checkEnter = this.checkEnter.bind(this);

        this.countDown = undefined;
    }

    componentDidMount() {
        this.args = parseTheURL(window.location);
        this.questionList = this.generateQuestion(this.args);
        window.questionList = this.questionList;


        this.initiateTask(this.questionList, this.args)
            .then(() => {
                this.startCountDown()
                    .then(() => {
                        this.nextQuestion()
                    })
            })
            .catch(err => console.error(err));
    }

    render() {
        return (
            <div className="text-center" >
                <div className={`d-${this.state.qDISP}`} >
                    <br /><br />
                    <div className="d-flex justify-content-center">
                        <div className="p-2">
                            <h1>{this.state.questionNum}</h1>
                        </div>
                    </div>
                </div>
                <div className={`d-${this.state.dDISP}`}>
                    <div className={`d-${this.state.dDISP1} justify-content-center`}>
                        <div className="p-2">
                            <h2>{locale("Enter in answer", "輸入答案", "输入答案")}:</h2>
                        </div>
                    </div>
                    <div className={`d-${this.state.dDISP1} justify-content-center`}>
                        <div className="p-2" id="answerInput" >
                            <input type="number" className="form-control d-inline text-center adaafasfasdfasdf" onChange={this.checkAnsInputOC} value={this.state.checkAnsInput} onKeyDown={this.checkEnter} />
                        </div>
                        <div className="p-2">
                            <button className="btn btn-primary d-inline" onClick={this.checkAns} >{locale("Check", "檢查結果", "检查结果")}</button>
                        </div>
                    </div>
                    <div className={`d-${this.state.dDISP2} justify-content-center`}>
                        <div className="p-2">
                            <h3>{this.state.isCorrect}</h3>
                            <h3>{locale("The correct answer is", "答案是", "答案是")} {this.state.correctAnswer}</h3>
                        </div>
                    </div>
                    <div className={`d-${this.state.dDISP2} justify-content-center`}>
                        <div className="p-2">
                            <CustomLinkButton show={this.state.dDISP2} />
                            <button className="btn btn-primary" onClick={() => { window.location.reload() }}>{locale("Retry", "重試", "重试")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }




    generateQuestion(args) {
        var numArr = [];

        var flag = true;
        for (var i = 0; i < args.totalNumbers; i++) {
            if (flag || chance(70)) {
                var genQ = random(Math.pow(10, args.numDigit - 1), Math.pow(10, args.numDigit));
                while (genQ === numArr[numArr.length - 1]) {
                    genQ = random(Math.pow(10, args.numDigit - 1), Math.pow(10, args.numDigit));
                }
                numArr.push(genQ);
                flag = false;
            } else {
                var genQ = -random(Math.pow(10, args.numDigit - 1), Math.pow(10, args.numDigit));
                while (genQ === numArr[numArr.length - 1]) {
                    genQ = -random(Math.pow(10, args.numDigit - 1), Math.pow(10, args.numDigit));
                }
                numArr.push(genQ);
                if (addArray(numArr) < 0) {
                    numArr[i] *= -1;
                }
            }
        }

        return { questions: numArr, answer: addArray(numArr) };
    }

    initiateTask(questionList, args) {
        return new Promise((resolve, reject) => {
            try {
                this.setState({ totalQuestions: questionList.questions.length, secLeft: args.timePer, currentQuestion: 0 }, () => {
                    resolve()
                });
            } catch (error) {
                reject(error)
            }
        })
    }

    startCountDown() {
        return new Promise((resolve, reject) => {
            resolve();
        })
    }

    nextQuestion() {
        if (this.state.currentQuestion === this.state.totalQuestions) {
            this.taskEnd();
            return;
        }


        this.setState({ secLeft: this.args.timePer, questionNum: this.questionList.questions[this.state.currentQuestion], currentQuestion: this.state.currentQuestion + 1 }, () => { this.startTime(this.nextQuestion) });
    }

    taskEnd() {
        this.setState({ dDISP: "block", qDISP: "none" })
    }

    startTime(end) {
        this.countDown = setInterval(() => {

            if (Number(this.state.secLeft) > 0) {
                this.setState({ secLeft: (Math.round(Number(this.state.secLeft) * 10 - 1) / 10).toFixed(1) })
            }
            else {
                this.setState({ secLeft: 0 })
                clearInterval(this.countDown);
                end()
            }
        }, 100)
    }

    checkAns() {
        if (Number(this.state.checkAnsInput) === this.questionList.answer) {
            this.setState({ isCorrect: locale("Correct", "正確", "正确"), dDISP1: "none", dDISP2: "flex", correctAnswer: this.questionList.answer });
        }
        else {
            this.setState({ isCorrect: locale("Wrong", "錯", "错"), dDISP1: "none", dDISP2: "flex", correctAnswer: this.questionList.answer });
        }
    }

    checkAnsInputOC(evt) {
        if (!isNaN(Number(evt.target.value))) {
            this.setState({ checkAnsInput: evt.target.value });
        }
    }

    checkEnter(evt) {
        var keyCode = evt.keyCode ? evt.keyCode : evt.which;
        if (keyCode === 13) {
            this.checkAns();
        }
    }
}

function parseTheURL(url) {
    var urlSearch = new URLSearchParams(url.search);
    var out = JSON.parse(atob(urlSearch.get("run")));

    return out;
}

function CustomLinkButton(props) {
    var history = useHistory()
    function clicked() {
        history.push("/app");
    }
    return (
        <button className={`btn btn-primary d-${props.show === "none" ? "none" : "inline"} mr-2`} onClick={clicked} >
            {locale("Go Home", "回首頁", "回首页")}
        </button>
    )
}

function addArray(arr) {
    var result = 0;
    arr.forEach((item) => {
        result += item;
    })
    return result;
}

function chance(perc) {
    let n = perc / 100;
    return !!n && Math.random() <= n;
}

function random(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}
