import React from 'react';
import { useHistory } from 'react-router-dom';

export function QuickReload(props) {

    var urlto = parseTheURL(window.location);
    var history = useHistory();

    history.push(urlto);

    return (<div></div>)
}

function parseTheURL(url) {
    var urlSearch = new URLSearchParams(url.search);
    var out = urlSearch.get("redir")

    return out;
}