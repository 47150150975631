import React, { useEffect, useState } from "react";

import clap from "../img/clap.gif";
import locale from "./normaljs/locale";

export default function Memorize() {
    const [view, setView] = useState(0);
    const [noMemorize, setNoMemorize] = useState();
    const [numberMin, setNumberMin] = useState();
    const [numberMax, setNumberMax] = useState();
    const [memDuration, setMemDuration] = useState();

    function start() {
        setView(1);
    }

    function finish() {
        setNoMemorize();
        setNumberMin();
        setNumberMax();
        setMemDuration();
        setView(0);
    }

    return <div>
        {view === 0 ?
            <Config noMemorize={noMemorize} setNoMemorize={setNoMemorize} numberMin={numberMin} setNumberMin={setNumberMin} numberMax={numberMax} setNumberMax={setNumberMax} memDuration={memDuration} setMemDuration={setMemDuration} start={start} />
            : <Activity noMemorize={noMemorize} numberMin={numberMin} numberMax={numberMax} memDuration={memDuration} finish={() => finish()} />}
    </div>
}

function Config({ noMemorize, setNoMemorize, numberMin, setNumberMin, numberMax, setNumberMax, memDuration, setMemDuration, start }) {
    return <div className="d-flex flex-column align-items-center text-center" >
        <div className="w-25 mt-2" >
            <input type="number" className="form-control text-center adaafasfasdfasdf" placeholder={locale("# of Sets to Memorize", "数字组数", "數字組數")} value={noMemorize} onChange={evt => setNoMemorize(evt.target.value)} />
        </div>
        <div className="w-25 mt-3" >
            <input type="number" className="form-control text-center adaafasfasdfasdf" placeholder={locale("Number Minimum", "最小数值", "最小數值")} value={numberMin} onChange={evt => setNumberMin(evt.target.value)} />
        </div>
        <div className="w-25 mt-3" >
            <input type="number" className="form-control text-center adaafasfasdfasdf" placeholder={locale("Number Maximum", "最大数值", "最大數值")} value={numberMax} onChange={evt => setNumberMax(evt.target.value)} />
        </div>
        <div className="w-25 mt-3" >
            <input type="number" className="form-control text-center adaafasfasdfasdf" placeholder={locale("Memorization Duration", "记忆时间", "記憶時間")} value={memDuration} onChange={evt => setMemDuration(evt.target.value)} />
        </div>
        <div className="w-25 mt-3" >
            <button className="btn btn-primary" onClick={start} >{locale("Start", "开始", "開始")}</button>
        </div>
    </div>
}

function Activity(props) {
    const [pState, setPState] = useState(0);
    const [numbers, setNumbers] = useState([]);
    //console.log(random(props.numberMin, props.numberMax), props.numberMin, props.numberMax);

    useEffect(() => {
        switch (pState % 4) {
            case 0:
                var n = [];
                var total = 0;
                for (var i = 0; i < props.noMemorize; i++) {
                    var x = random(props.numberMin, props.numberMax)
                    if (total + x < 0) x *= -1;
                    n.push(x);
                }
                setNumbers(n);
                setTimeout(() => setPState(pState + 1), props.memDuration * 1000);
                break;
            default: break;
        }
    }, [pState])

    function calcPState() {
        switch (pState % 4) {
            case 0:
                return <List numbers={numbers} next={false} />;
            case 1:
                return <ClapPage nextFunc={() => nextFunc()} />
            case 2:
                return <>
                    <List numbers={numbers} next={true} nextFunc={() => nextFunc()} homeFunc={() => props.finish()} />
                </>
            case 3:
                return <ClapPage nextFunc={() => nextFunc()} />
            default: break;
        }
    }

    function nextFunc() {
        setPState(pState + 1);
    }

    return <>
        {calcPState()}
    </>
}

function List({ numbers, next, nextFunc, homeFunc }) {
    return <div className="d-flex flex-column align-items-center mt-3" >
        {numbers.map((val, index) => {
            return (
                <div key={index} >
                    <h1>{val}</h1>
                </div>
            )
        })}
        <div className={`${next ? "d-block" : "d-none"}`} >
            <button className="btn btn-primary" onClick={() => homeFunc()} >{locale("Home", "首页", "首頁")}</button>
            <button className="btn btn-primary ml-2" onClick={() => nextFunc()} >{locale("Next", "下一步", "下一步")}</button>
        </div>
    </div>
}

function random(min, max) {
    return Math.floor(Math.random() * (parseFloat(max) - parseFloat(min))) + parseFloat(min);
}

function ClapPage({nextFunc}) {
    return <div className="d-flex flex-column align-items-center justify-content-center" >
        <div>
            <img src={clap} alt="..." />
        </div>
        <div className="mt-2" >
            <button className="btn btn-primary ml-2" onClick={() => nextFunc()} >{locale("Next", "下一步", "下一步")}</button>
        </div>
    </div>
}