import React from 'react';
import { QuickPrac } from './QuickPrac';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { TaskAS } from './TaskAS';
import { TaskMD } from './TaskMD';

export function App() {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <QuickPrac />
            </Route>
            <Route path={`${path}/as`}>
                <TaskAS />
            </Route>
            <Route path={`${path}/md`}>
                <TaskMD />
            </Route>
        </Switch>
    )
}