import React from 'react';
import '../css/task.css';
import '../css/core.css';
import { useHistory } from 'react-router-dom';
import locale from './normaljs/locale';

export class TaskMD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            correct: 0,
            incorrect: 0,
            currentTime: "5",
            answerInput: "",
            remainderInput: "",
            num1: 0,
            operator: <Operator operator="*" />,
            num2: 0,
            num1Prev: null,
            num2Prev: null,
            operatorPrev: null,
            ansPrev: null,
            taskDISP: "block",
            doneDISP: "none",
            timeDISP: "none",
        }

        this.currentQuestion = 0;

        this.input = undefined;

        this.submitted = this.submitted.bind(this);
        this.timer = this.timer.bind(this);
        this.generateQuestions = this.generateQuestions.bind(this);
        this.genQM = this.genQM.bind(this);
        this.genQD = this.genQD.bind(this);
        this.checkAns = this.checkAns.bind(this);
        this.end = this.end.bind(this);
        this.inputUpdate = this.inputUpdate.bind(this);
    }

    componentDidMount() {
        this.args = parseTheURL(window.location);
        //console.log(this.args);
        this.questions = this.generateQuestions();
        //console.log(this.questions);

        this.currentQuestion = 0;
        this.setState({ currentTime: this.args.duration, totalQuestions: this.args.noSum }, () => {
            this.timer(() => this.end(false));
            this.nextQuestion();
        })

    }

    render() {
        return (
            <div>
                <div className={`text-center noselect d-${this.state.taskDISP}`} >
                    <div className="d-flex justify-content-center m-2">
                        <div className="p-2" >
                            {locale("Correct", "對的題目", "对的题目")}: {this.state.correct}
                        </div>
                        <div className="p-2" >
                            {locale("Incorrect", "錯的題目", "错的题目")}: {this.state.incorrect}
                        </div>
                        <div className="p-2">
                            {locale(`${this.state.currentTime}s left`, `剩 ${this.state.currentTime} 秒`, `剩 ${this.state.currentTime} 秒`)}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="p-2">
                            <h1> {this.state.num2Prev}&nbsp;{this.state.operatorPrev}&nbsp;{this.state.num1Prev}&nbsp;{this.state.num1Prev ? "=" : null} {this.state.ansPrev} </h1>
                        </div>
                        <div className="p-2">
                            <h1>{this.state.num2}&nbsp;{this.state.operator}&nbsp;{this.state.num1}</h1>
                        </div>
                        <div className="p-2 justify-content-center d-flex flex-row">
                            <div className="p-2" id="answerInput" >
                                <form onSubmit={this.submitted} className="d-flex" >
                                    <input type="submit" className="d-none" />
                                    <input type="number" className="form-control text-center d-inline mr-2 adaafasfasdfasdf"
                                        value={this.state.answerInput} placeholder={locale("Answer", "商", "商")} onChange={this.inputUpdate} autoFocus />
                                    {this.args?.doRemainder ? <input type="number" className="form-control text-center d-inline mr-2 adaafasfasdfasdf"
                                        placeholder={locale("Remainder", "餘數", "余数")} value={this.state.remainderInput} onChange={(evt) => this.remainderUpdate(evt)} /> : null}
                                    <button className="btn btn-primary d-inline" >{locale("Submit", "發送", "发送")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`text-center noselect d-${this.state.doneDISP}`}>
                    <h3>{locale(`You got ${this.state.correct}/${this.state.totalQuestions} questions correct.`, `你做對了 ${this.state.correct}/${this.state.totalQuestions} 個問題。`, `你做对了 ${this.state.correct}/${this.state.totalQuestions} 个问题。`)}</h3><br />
                    <CustomLinkButton /> <QuickReload where={window.location.pathname + window.location.search} />
                </div>
                <div className={`text-center noselect d-${this.state.timeDISP}`}>
                    <h1>{locale("Sorry, you ran out of time.",
                        "對不起，時間到了",
                        "对不起，时间到了")}</h1>
                    <h3>{locale(`You got ${this.state.correct}/${this.state.totalQuestions} questions correct.`,
                        `你做對了 ${this.state.correct}/${this.state.totalQuestions} 個問題。`,
                        `你做对了 ${this.state.correct}/${this.state.totalQuestions} 个问题。 `)}</h3>
                    <h3>{locale(`You did ${this.currentQuestion ? this.currentQuestion : null} questions before running out of time.`,
                        `你規定時間內做了 ${this.currentQuestion ? this.currentQuestion : null} 個問題。`,
                        `你规定时间内做了 ${this.currentQuestion ? this.currentQuestion : null} 个问题。`)}</h3>
                    <CustomLinkButton /> <QuickReload where={window.location.pathname + window.location.search} />
                </div>
            </div>
        )
    }

    generateQuestions() {
        if (this.args.mode === "mult") {
            return this.genQM();
        }
        else return this.genQD();
    }

    genQD() {
        var questionList = [];
        for (var i = 0; i < this.args.noSum; i++) {
            var ans = random(this.args.minAns, this.args.maxAns);
            var div = random(this.args.minDiv, this.args.maxDiv);
            var other = ans * div;
            var remainder = random(0, div - 1);
            //console.log(remainder);
            //console.log(other);
            if (this.args.doRemainder) other += remainder;

            questionList.push({ num2: other, operator: <Operator operator="/" />, num1: div, answer: ans, remainder: remainder });
        }

        //console.log(questionList);
        return questionList;
    }
    genQM() {
        var questionList = []
        for (var i = 0; i < this.args.noSum; i++) {
            var num1 = random(this.args.min1, this.args.max1);
            var num2 = random(this.args.min2, this.args.max2);
            var ans = num1 * num2;
            questionList.push({ num1: num1, operator: <Operator operator="*" />, num2: num2, answer: ans });
        }
        return questionList;
    }

    nextQuestion() {
        if (this.currentQuestion === this.args.noSum) {
            this.end(true)
            return;
        }

        if (this.input) this.input.focus()

        this.setState({
            num1: this.questions[this.currentQuestion].num1,
            operator: this.questions[this.currentQuestion].operator,
            num2: this.questions[this.currentQuestion].num2,
            answer: this.args.mode === "mult" ? this.questions[this.currentQuestion].num1 * this.questions[this.currentQuestion].num2 : this.questions[this.currentQuestion].answer,
            answerInput: "",
            remainder: this.questions[this.currentQuestion].remainder,
            remainderInput: "",
        })
        if (this.currentQuestion !== 0) {
            this.setState({
                num1Prev: this.questions[this.currentQuestion - 1].num1,
                num2Prev: this.questions[this.currentQuestion - 1].num2,
                ansPrev: this.questions[this.currentQuestion - 1].answer += this.args.doRemainder ? " R " + this.questions[this.currentQuestion - 1].remainder : "",
                operatorPrev: this.questions[this.currentQuestion].operator
            });
        }
        this.currentQuestion++;
    }

    end(reason) {
        if (reason) {
            this.setState({ taskDISP: "none", doneDISP: "block" });
        }
        else if (this.state.doneDISP === "none") {
            this.setState({ taskDISP: "none", timeDISP: "block" });
        }
    }


    submitted(evt) {
        evt.preventDefault();
        this.checkAns();
    }

    checkAns() {
        //console.log(Number(this.state.remainderInput));
        if (Number(this.state.answerInput) === this.state.answer) {
            if (this.args?.doRemainder) {
                if (Number(this.state.remainderInput) === this.state.remainder)
                    this.setState({ correct: this.state.correct + 1 });
                else
                    this.setState({ incorrect: this.state.incorrect + 1 });
            }
            else
                this.setState({ correct: this.state.correct + 1 });
        }
        else {
            this.setState({ incorrect: this.state.incorrect + 1 });
        }

        this.nextQuestion();
    }

    inputUpdate(evt) {
        this.input = evt.target;
        if (!isNaN(Number(evt.target.value))) {
            this.setState({ answerInput: evt.target.value });
        }
    }

    remainderUpdate(evt) {
        if (!isNaN(Number(evt.target.value))) {
            this.setState({ remainderInput: evt.target.value });
        }
    }


    timer(callback) {
        this.countDown = setInterval(() => {

            if (Number(this.state.currentTime) > 0) {
                this.setState({ currentTime: (Math.round(Number(this.state.currentTime) * 10 - 1) / 10).toFixed(1) })
            }
            else {
                this.setState({ curentTime: 0 })
                clearInterval(this.countDown);
                callback()
            }
        }, 100)
    }
}

function Operator(props) {
    if (props.operator === "/") {
        return <i className="fas fa-divide fa-sm" ></i>
    }
    else if (props.operator === "*") {
        return <i className="fas fa-times fa-sm" ></i>
    }
}

function parseTheURL(url) {
    var urlSearch = new URLSearchParams(url.search);
    var out = JSON.parse(atob(urlSearch.get("run")));

    return out;
}

function random(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

function CustomLinkButton() {
    var history = useHistory()
    function clicked() {
        history.push("/app");
    }
    return (
        <button className={`btn btn-primary d-inline mr-2`} onClick={clicked} >
            {locale("Go Home", "回首頁", "回首页")}
        </button>
    )
}

function QuickReload(where) {
    var history = useHistory()
    function clicked() {
        history.push("/quickRef?redir=" + where.where);
    }
    return (
        <button className={`btn btn-primary d-inline mr-2`} onClick={clicked} >
            {locale("Retry", "重試", "重试")}
        </button>
    )
}
