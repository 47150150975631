import React from 'react';

export class Terms extends React.Component {
    render() {
        return (
            <div>
                Terms and Conditions:
                The ABC Abacus Terms of service ("terms") is an legally binding agreement between you ("you" and "your")
                and the creator of this product (the "creator").<br /><br />


                By using the ABC Abacus Service you are indicating that you have read to and agree
                to these terms, privacy policy, and cookie policy, and you agree and consent to these terms and conditions.
                These terms and conditions set forth your rights and obligations to your use of any version
                of the ABC Abacus Service (the "product" or "service").

                The "creator" provides this product without ANY WARRANTY, and is not liable for any damage or loss that may arise
                from the usage of this product, including(but not limited to) direct usage, indirect usage,
                content, abuse, or misuse of the product.

                The "creator" will use reasonable efforts to protect personal information submitted to the service in connection with the service and
                shall use such information in accordance with the privacy policy.

                You acknowledge that any and all submission of your information is at your sole risk, and
                to the maximum amount permitted by law. the creator hereby disclaims any and all
                loss and/or liability related to the information in this way.


                TO THE MAXIMUM EXTENT PERMITTED BY LAW, ABC ABACUS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
                WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A)
                YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE;
                (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
                OTHER USERS OR THIRD PARTIES;
                OR (C) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.
                IN NO EVENT SHALL ABC ABACUS'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED ONE U.S. DOLLARS (U.S. $1.00).
                <br />

                If we cause damage to you and you're a consumer in the EEA, the above doesn’t apply.
                Instead, ABC Abacus’s liability will be limited to foreseeable damages arising due to a breach of material contractual obligations typical for this type of contract.
                ABC Abacus isn’t liable for damages that result from a non-material breach of any other applicable duty of care.
                This limitation of liability won’t apply to any statutory liability that cannot be limited,
                to liability for death or personal injury caused by our negligence or willful misconduct,
                or if and to exclude our responsibility for something we have specifically promised to you.

                Effective May 27, 2020
            </div>
        )
    }
}